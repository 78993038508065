<section class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error">
                    <h1>404</h1>
                    <p>We're sorry but it looks like that page doesn't exist anymore.</p>

                    <form>
                        <input type="text" class="form-control" placeholder="Try searching for what you were looking for...">
                        <button type="submit" class="btn btn-primary"><i class="icofont-search-2"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>