<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" class="navbar-logo" alt="image" /></a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
            >Home
          </a>
        </li>

        <li class="nav-item">
          <a
            routerLink="/services"
            class="nav-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Onze Diensten
          </a>
        </li>

        <li class="nav-item">
          <a
            routerLink="projects"
            class="nav-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            Projecten
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
            >Over Ons</a
          >
        </li>

        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a
            routerLink="/contact"
            class="nav-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Contact</a
          >
        </li>
      </ul>

      <div class="others-option">
        <!-- <div class="lang-switcher">
          <select>
            <option>Eng</option>
            <option>Fra</option>
          </select>
        </div> -->

        <!-- <div class="call-us">
          <span>Bel ons:</span> <a href="tel:+31648125756">+316 48 12 57 56</a>
        </div> -->

        <div class="modal-taggle-button">
          <a href="#" data-toggle="modal" data-target="#sidebarModal"><span></span></a>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="modal right fade sidebarModal" id="sidebarModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="logo">
          <a routerLink="/"><img src="assets/img/logo.png" class="logo-w-100" alt="logo" /></a>
        </div>
        <p>
          Wij zijn een full-service digital agency gespecialiseerd in het bedenken én realiseren van innovatieve
          digitale oplossingen.
        </p>
        <ul class="modal-contact-info">
          <li>
            <i class="icofont-google-map"></i>
            <strong>Liesbosweg 166</strong> Etten-Leur. 4872NE, NL
          </li>
          <li>
            <i class="icofont-ui-call"></i>
            <strong>+316 48 12 57 56</strong> Kunt ons altijd even bellen :)
          </li>
          <li>
            <i class="icofont-envelope"></i>
            <strong>hello@sr-development.nl</strong> 24/7 online support
          </li>
        </ul>
        <ul class="social-links">
          <li>
            <a href="#"><i class="icofont-facebook"></i></a>
          </li>
          <li>
            <a href="#"><i class="icofont-instagram"></i></a>
          </li>
          <li>
            <a href="#"><i class="icofont-linkedin"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
