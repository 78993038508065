import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceBlock } from '../../../interface/serviceblock';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss'],
})
export class ServicesDetailsComponent implements OnInit {
  serviceTitle: string;
  showSecondBlock: boolean = false;

  serviceBlock1: ServiceBlock = new ServiceBlock();
  serviceBlock2: ServiceBlock = new ServiceBlock();

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      switch (params.service) {
        case 'digital-design-and-branding':
          this.setDesignAndBrandingTexts();
          break;
        case 'web-development':
          this.serviceTitle = 'Web Development';
          this.serviceBlock1.title =
            'Ontwikkelen van creatieve oplossingen ondersteund door de nieuwste technologieën in de industrie.';
          this.serviceBlock1.img = 'assets/img/service-webdev.png';
          this.serviceBlock1.features = [
            'Websites',
            'Webshops',
            'Business applications',
            'Platforms and portals',
            'Dashboards',
            "Progressive Web Apps (PWA's)",
            'Landing pages',
          ];
          break;
        case 'mobile-development':
          this.serviceTitle = 'Mobile Development';
          this.serviceBlock1.title =
            'De beste UX door onze mobile first development.';
          this.serviceBlock1.img = 'assets/img/service-appdev.png';
          this.serviceBlock1.features = [
            'IOS apps',
            'Android apps',
            'Hybrid apps',
            'Responsive development',
          ];
          break;
      }
    });
  }

  setDesignAndBrandingTexts() {
    this.showSecondBlock = true;
    this.serviceTitle = 'Digital design & Branding';
    this.serviceBlock1.title =
      'Ontwerpen van gebruiksvriendelijke digitale interfaces';
    this.serviceBlock1.img = 'assets/img/service-design.png';
    this.serviceBlock1.features = [
      'Prototyping',
      'User Experience design',
      'User Interface design',
      'Visual design',
    ];

    this.serviceBlock2.title =
      'Herkenbare branding welk uw zakelijke waarden weerspiegelen';
    this.serviceBlock2.img = 'assets/img/service-branding.png';
    this.serviceBlock2.features = [
      'Brand identity',
      "Logo's",
      'Style guides',
      'Offline communication',
      'Graphic design',
    ];
  }
}
