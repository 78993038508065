<div class="page-title animatedBackground">
  <div class="container">
    <h3>Over ons</h3>
    <ul>
      <li><a router="/">Home</a></li>
      <li class="dot-divider"></li>
      <li>Over ons</li>
    </ul>
  </div>
  <div class="bg-pattern">
    <img src="assets/img/bg-pattern-2.png" alt="pattern" />
  </div>
</div>

<section class="features-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="row about-image">
          <div class="col-6 col-lg-6 col-md-6">
            <div class="image">
              <img src="assets/img/about-img1.jpg" alt="about" />
            </div>
          </div>
          <div class="col-6 col-lg-6 col-md-6">
            <div class="image">
              <img src="assets/img/about-img2.jpg" alt="about" />
            </div>
          </div>
          <div class="col-6 col-lg-6 col-md-6">
            <div class="image mt-30">
              <img src="assets/img/about-img3.jpg" alt="about" />
            </div>
          </div>
          <div class="col-6 col-lg-6 col-md-6">
            <div class="image mt-30">
              <img src="assets/img/about-img4.jpg" alt="about" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-text">
          <span>Best Digital Agency in the World</span>
          <h3>Wij helpen je business groeien!</h3>
          <p>
            We ontwerpen en ontwikkelen digitale producten en services van
            eerste idee tot professioneel online platform. Door een combinatie
            van ervaring, gezond verstand en een betrokken houding zetten we
            creatieve ideëen en business doelstellingen om in resultaten.
          </p>
          <p>
            Een applicatie is nooit een doel op zich, maar wordt ontwikkeld als
            medium om doelstellingen te behalen. Met geavanceerde tools focussen
            wij ons op de volledige customer journey waarbij "return on
            investment" centraal staat.
          </p>
          <p>
            We hebben diverse website en applicaties ontwikkeld voor
            toonaangevende merken van klein tot grote bedrijven in vele
            industrieën in Nederland.
          </p>
        </div>
      </div>
    </div>

    <div class="row ptb-100 align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="features-text">
          <h3>Haal meer uit je software of website!</h3>
          <p>
            We werken systematisch om maatschappelijk verantwoord ondernemen te
            integreren in onze kernactiviteiten en stellen onze expertise ter
            beschikking ten behoeve van de industrieën waarin we actief zijn.
          </p>
          <ul>
            <li>
              Wij beschikken kennis en expertise over de nieuwste technieken in
              de markt.
            </li>
            <li>Ervaren met kleine en grootschalige projecten.</li>
            <li>Gebruiksvriendelijkheid staat bij ons op nummer 1.</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="img mb-0">
          <img src="assets/img/features-img1.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
  <div class="rocket"><img src="assets/img/rocket.png" alt="rocket" /></div>
  <div class="vector"><img src="assets/img/vector-bg.png" alt="vector" /></div>
</section>

<section class="working-process-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="section-title">
      <span>Work Flow</span>
      <h3>Onze werkwijze</h3>
      <p>
        We passen onze strategieën die in de voorhoede van de industrie staan
        graag aan om elke klant de beste oplossingen te bieden.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-work-process">
          <div class="icon">
            <i class="icofont-read-book"></i>
          </div>
          <h3>Planning</h3>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-work-process">
          <div class="icon">
            <i class="icofont-light-bulb"></i>
          </div>
          <h3>Research</h3>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-work-process">
          <div class="icon">
            <i class="icofont-mathematical"></i>
          </div>
          <h3>Optimizing</h3>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-work-process">
          <div class="icon">
            <i class="icofont-laptop-alt"></i>
          </div>
          <h3>Results</h3>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="history-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="section-title">
      <span>Our Story</span>
      <h3>The Story of Turacos</h3>
      <p>
        We enjoy adapting our strategies to offer every client the best
        solutions that are at the forefront of the industry.
      </p>
    </div>
    <ul class="timeline clearfix">
      <li>
        <div class="timeline-badge"></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h3>Our Start</h3>
          </div>
          <div class="timeline-body">
            <p>
              Invitamus me testatur sed quod non dum animae tuae lacrimis ut
              libertatem deum rogus aegritudinis causet. Dicens hoc contra
              serpentibus isto.
            </p>
          </div>
          <div class="timeline-footer">
            <p>1988 - 1992</p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-badge"></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h3>A Growing Reputation</h3>
          </div>
          <div class="timeline-body">
            <p>
              Invitamus me testatur sed quod non dum animae tuae lacrimis ut
              libertatem deum rogus aegritudinis causet. Dicens hoc contra
              serpentibus isto.
            </p>
          </div>
          <div class="timeline-footer">
            <p>1993 - 1997</p>
          </div>
        </div>
      </li>
      <li>
        <div class="timeline-badge"></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h3>Won the First Prize</h3>
          </div>
          <div class="timeline-body">
            <p>
              Invitamus me testatur sed quod non dum animae tuae lacrimis ut
              libertatem deum rogus aegritudinis causet. Dicens hoc contra
              serpentibus isto.
            </p>
          </div>
          <div class="timeline-footer">
            <p>1998 - 2001</p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-badge"></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h3>A National Expansion</h3>
          </div>
          <div class="timeline-body">
            <p>
              Invitamus me testatur sed quod non dum animae tuae lacrimis ut
              libertatem deum rogus aegritudinis causet. Dicens hoc contra
              serpentibus isto.
            </p>
          </div>
          <div class="timeline-footer">
            <p>2001 - 2005</p>
          </div>
        </div>
      </li>
      <li>
        <div class="timeline-badge"></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h3>International Expansion</h3>
          </div>
          <div class="timeline-body">
            <p>
              Invitamus me testatur sed quod non dum animae tuae lacrimis ut
              libertatem deum rogus aegritudinis causet. Dicens hoc contra
              serpentibus isto.
            </p>
          </div>
          <div class="timeline-footer">
            <p>2005 - 2010</p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-badge"></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h3>A Brief History</h3>
          </div>
          <div class="timeline-body">
            <p>
              Invitamus me testatur sed quod non dum animae tuae lacrimis ut
              libertatem deum rogus aegritudinis causet. Dicens hoc contra
              serpentibus isto.
            </p>
          </div>
          <div class="timeline-footer">
            <p>2010 - 2020</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</section> -->

<section class="funfacts-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-6 col-lg-4 col-sm-4 col-md-4">
        <div class="fun-fact">
          <i class="icofont-users-alt-5"></i>
          <p>Active Clients:</p>
          <h3>
            <span class="odometer" data-count="10">00</span
            ><span class="sign">+</span>
          </h3>
        </div>
      </div>

      <div class="col-6 col-lg-4 col-sm-4 col-md-4">
        <div class="fun-fact">
          <i class="icofont-rocket-alt-2"></i>
          <p>Success Rate:</p>
          <h3>
            <span class="odometer" data-count="100">00</span
            ><span class="sign">%</span>
          </h3>
        </div>
      </div>

      <div class="col-6 col-lg-4 col-sm-4 col-md-4">
        <div class="fun-fact">
          <i class="icofont-laptop-alt"></i>
          <p>Projects Done:</p>
          <h3>
            <span class="odometer" data-count="20">00</span
            ><span class="sign">+</span>
          </h3>
        </div>
      </div>

      <!-- <div class="col-6 col-lg-3 col-sm-3 col-md-3">
        <div class="fun-fact">
          <i class="icofont-award"></i>
          <p>Awwards:</p>
          <h3>
            <span class="odometer" data-count="35">00</span
            ><span class="sign">+</span>
          </h3>
        </div>
      </div> -->
    </div>
  </div>
</section>

<section class="testimonial-area pb-0 ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="section-title">
      <span>Testimonial</span>
      <h3>Wat zeggen onze klanten?</h3>
      <p>
        We passen onze strategieën graag aan om elke klant de beste oplossingen
        te bieden! Dit alles met de nieuwste en snelste technieken in de markt.
      </p>
    </div>
    <div class="feedback-slides owl-carousel owl-theme">
      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar1.jpg" alt="client" />
          </div>
          <h4>Mick Geelen</h4>
          <span>Ondernemer</span>
        </div>
        <p>
          SR Development gaat zo veel verder dan andere web agencies. Met een
          unieke stijl en persoonlijkheid helpen ze mij in een ware
          groeitransformatie. Waar ik zocht naar een manier om mijn business
          online zichtbaar te maken, hebben zij mij geholpen om mijn business
          online schaalbaar te maken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar2.jpg" alt="client" />
          </div>
          <h4>Ronny</h4>
          <span>Barbier</span>
        </div>
        <p>
          SR Development is een zeer klantvriendelijk bedrijf die kwaliteit hoog
          in het vaandel heeft staan. De medewerkers zijn vriendelijk,
          behulpzaam en denken in creatieve oplossingen. Wat ik enorm waardeeer
          is dat ze meedenken en ideeën aandragen. De uitstraling van de website
          is precies wat ik zocht!
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar3.jpg" alt="client" />
          </div>
          <h4>Lina Souja</h4>
          <span>Owner Social Media Marketing Agency</span>
        </div>
        <p>
          Het is een feest om met SR Development samen te werken. De supernerds
          die ze in huis hebben zijn echt goed. Ze bouwen slimme platformen, en
          denken altijd met je mee. Het zijn fijne mensen die oprecht vanuit hun
          hart werken. Kortom een no brainer, je zult er geen spijt van krijgen
          om met SR Development te werken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar1.jpg" alt="client" />
          </div>
          <h4>Mick Geelen</h4>
          <span>Ondernemer</span>
        </div>
        <p>
          SR Development gaat zo veel verder dan andere web agencies. Met een
          unieke stijl en persoonlijkheid helpen ze mij in een ware
          groeitransformatie. Waar ik zocht naar een manier om mijn business
          online zichtbaar te maken, hebben zij mij geholpen om mijn business
          online schaalbaar te maken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar2.jpg" alt="client" />
          </div>
          <h4>Ronny</h4>
          <span>Barbier</span>
        </div>
        <p>
          SR Development is een zeer klantvriendelijk bedrijf die kwaliteit hoog
          in het vaandel heeft staan. De medewerkers zijn vriendelijk,
          behulpzaam en denken in creatieve oplossingen. Wat ik enorm waardeeer
          is dat ze meedenken en ideeën aandragen. De uitstraling van de website
          is precies wat ik zocht!
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar3.jpg" alt="client" />
          </div>
          <h4>Lina Souja</h4>
          <span>Owner Social Media Marketing Agency</span>
        </div>
        <p>
          Het is een feest om met SR Development samen te werken. De supernerds
          die ze in huis hebben zijn echt goed. Ze bouwen slimme platformen, en
          denken altijd met je mee. Het zijn fijne mensen die oprecht vanuit hun
          hart werken. Kortom een no brainer, je zult er geen spijt van krijgen
          om met SR Development te werken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>
    </div>
  </div>
  <div class="rocket fadeInUp wow slow">
    <img src="assets/img/rocket.png" alt="rocket" />
  </div>
  <div class="vector"><img src="assets/img/vector-bg.png" alt="vector" /></div>
</section>

<!-- <section class="team-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Our Team</span>
      <h3>Our Best Experts</h3>
      <p>
        We enjoy adapting our strategies to offer every client the best
        solutions that are at the forefront of the industry.
      </p>
      <a routerLink="/team" class="read-more-btn">Meet All</a>
    </div>

    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team">
          <img src="assets/img/team1.jpg" alt="team" />
          <div class="team-content">
            <h3>Matthew Hunter</h3>
            <span>CEO & Founder</span>
            <ul>
              <li>
                <a href="#"><i class="icofont-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team">
          <img src="assets/img/team2.jpg" alt="team" />
          <div class="team-content">
            <h3>David Andy</h3>
            <span>Marketing Head</span>
            <ul>
              <li>
                <a href="#"><i class="icofont-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team">
          <img src="assets/img/team3.jpg" alt="team" />
          <div class="team-content">
            <h3>James Amith</h3>
            <span>Team Lead</span>
            <ul>
              <li>
                <a href="#"><i class="icofont-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team">
          <img src="assets/img/team4.jpg" alt="team" />
          <div class="team-content">
            <h3>Aston Agar</h3>
            <span>Project Manager</span>
            <ul>
              <li>
                <a href="#"><i class="icofont-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="icofont-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<div class="partner-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="partner-slides owl-carousel owl-theme">
      <div class="partner-item">
        <a href="#"
          ><img
            src="assets/img/partner-1.png"
            class="rounded-img"
            alt="partner"
        /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-2.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-3.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-4.png" alt="partner" /></a>
      </div>
      <!-- <div class="partner-item">
        <a href="#"><img src="assets/img/partner-1.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-2.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-3.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-4.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-5.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-6.png" alt="partner" /></a>
      </div> -->
    </div>
  </div>
  <div class="vector"><img src="assets/img/vector-bg.png" alt="vector" /></div>
</div>

<app-subscribe></app-subscribe>
