<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner-four item-bg-1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-image">
                                <img src="assets/img/main-banner.png" alt="banner-img">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1>We Are A Full Service <span>Digital</span> <span>Marketing</span> Agency</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dumm.</p>
                                <a routerLink="#" class="btn btn-primary">Get Started</a>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn">Play Now<i class="icofont-play-alt-3"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner-four item-bg-2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-image">
                                <img src="assets/img/banner-img1.png" alt="banner-img">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1>Leading the way in creative <span>Digital</span> <span>Professional</span></h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dumm.</p>
                                <a routerLink="#" class="btn btn-primary">Get Started</a>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn">Play Now<i class="icofont-play-alt-3"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner-four item-bg-3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-image">
                                <img src="assets/img/main-banner.png" alt="banner-img">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1>We Are A Full Service <span>Digital</span> <span>Marketing</span> Agency</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dumm.</p>
                                <a routerLink="#" class="btn btn-primary">Get Started</a>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn">Play Now<i class="icofont-play-alt-3"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img1.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img2.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img3.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img4.jpg" alt="about">
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="about-text">
                    <span>Best Digital Agency in the World</span>
                    <h3>The #1 SEO Services Company</h3>
                    <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                    <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing</p>
                    <p>We have developed search strategies for leading brands to small and medium sized businesses across many industries in the UK and worldwide.</p>
                    <a routerLink="#" class="read-more-btn">View More About us.</a>
                </div>
            </div>
        </div>
        
        <div class="row ptb-100 align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="features-text">
                    <h3>Get best plans for more Power</h3>
                    <p>We work systematically to integrate corporate responsibility in our core business and make our expertise available for the benefit of the societies where we operate.</p>
                    <ul>
                        <li>We've been supporting WordPress since the beginning.</li>
                        <li>Perfect for large sites or agencies managing multiple clients.</li>
                        <li>Our easy-to-use control panel and API let you spend</li>
                    </ul>
                    <a routerLink="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="img mb-0">
                    <img src="assets/img/features-img1.png" alt="image">
                </div>
            </div>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="img">
                    <img src="assets/img/features-img2.png" alt="image">
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="features-text mb-0">
                    <h3>It positive feelings about the brand</h3>
                    <p>We work systematically to integrate corporate responsibility in our core business and make our expertise available for the benefit of the societies where we operate.</p>
                    <ul>
                        <li>We've been supporting WordPress since the beginning.</li>
                        <li>Perfect for large sites or agencies managing multiple clients.</li>
                        <li>Our easy-to-use control panel and API let you spend</li>
                    </ul>
                    <a routerLink="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>
        </div>
    </div>
    <div class="rocket"><img src="assets/img/rocket.png" alt="rocket"></div>
    <div class="vector"><img src="assets/img/vector-bg.png" alt="vector"></div>
</section>

<section class="services-area bg ptb-100">
    <div class="container">
        <div class="section-title">
            <span>We work with you not for you</span>
            <h3>We’r provided best digital marketing services!</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
            <a routerLink="#" class="read-more-btn">view more services</a>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Strategy & Research</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor amet incididunt.</p>
                    <a href="#" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-arrows-axis"></i>
                    </div>
                    <h3>Reports & Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor amet incididunt.</p>
                    <a href="#" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-rocket-alt-2"></i>
                    </div>
                    <h3>Managment & Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor amet incididunt.</p>
                    <a href="#" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie"></i>
                    </div>
                    <h3>Infographics Content</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor amet incididunt.</p>
                    <a href="#" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-location-arrow"></i>
                    </div>
                    <h3>Media Promotion</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor amet incididunt.</p>
                    <a href="#" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-live-support"></i>
                    </div>
                    <h3>Quick Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor amet incididunt.</p>
                    <a href="#" class="read-more-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="analysis-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="analysis-form">
                    <h3>Free SEO Analysis</h3>
                    <form>
                        <div class="form-group">
                            <input type="text" placeholder="Name*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="email" placeholder="Email*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Phone*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Website*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Company Name*" class="form-control">
                        </div>
                        <button type="submit" class="btn btn-primary">Get Free Report</button>
                    </form>
                </div>
            </div>
            
            <div class="col-lg-7 col-md-12">
                <div class="analysis-text">
                    <span>Free SEO Analysis</span>
                    <h3>Be on the Top & Get More Traffic to Your Website</h3>
                    <p>The following services explain how we approach SEO for a range of common purposes:</p>
                    <ul>
                        <li>If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                        <li>For businesses which sell products online and and improve their product listings in the search results.</li>
                        <li>If you’re investing in a new website it’s important to ensure it’s built to succeed in the search results too.</li>
                        <li>If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="project-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Recent Works</span>
            <h3>We’ve done lot’s of work, Let’s Check some from here</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
            <a routerLink="#" class="read-more-btn">view more project</a>
        </div>
        
        <div class="shorting-menu">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".brand">Brand</button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".graphic">Graphic</button>
            <button class="filter" data-filter=".photoshop">Photoshop</button>
            <button class="filter" data-filter=".illustrator">Illustrator</button>
        </div>
    </div>
    <div class="shorting">
        <div class="row m-0">
            <div class="col-lg-4 col-md-6 col-sm-6 mix brand p-0">
                <div class="single-work">
                    <img src="assets/img/work-img1.jpg" alt="work-img">
                    <div class="work-content">
                        <h3>Bubble Wrap</h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Brand</a></li>
                        </ul>
                    </div>
                    <a routerLink="#" class="link-btn"><i class="icofont-link"></i></a>
                    <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 mix design p-0">
                <div class="single-work">
                    <img src="assets/img/work-img2.jpg" alt="work-img">
                    <div class="work-content">
                        <h3>Fluffernutter</h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Brand</a></li>
                        </ul>
                    </div>
                    <a routerLink="#" class="link-btn"><i class="icofont-link"></i></a>
                    <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 mix graphic p-0">
                <div class="single-work">
                    <img src="assets/img/work-img3.jpg" alt="work-img">
                    <div class="work-content">
                        <h3>Crock-Pot</h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Brand</a></li>
                        </ul>
                    </div>
                    <a routerLink="#" class="link-btn"><i class="icofont-link"></i></a>
                    <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 mix photoshop p-0">
                <div class="single-work">
                    <img src="assets/img/work-img4.jpg" alt="work-img">
                    <div class="work-content">
                        <h3>Chapstick </h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Brand</a></li>
                        </ul>
                    </div>
                    <a routerLink="#" class="link-btn"><i class="icofont-link"></i></a>
                    <a href="assets/img/work-img4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 mix design p-0">
                <div class="single-work">
                    <img src="assets/img/work-img5.jpg" alt="work-img">
                    <div class="work-content">
                        <h3>Kleenex</h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Brand</a></li>
                        </ul>
                    </div>
                    <a routerLink="#" class="link-btn"><i class="icofont-link"></i></a>
                    <a href="assets/img/work-img5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 mix illustrator p-0">
                <div class="single-work">
                    <img src="assets/img/work-img6.jpg" alt="work-img">
                    <div class="work-content">
                        <h3>Ping-Pong</h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Brand</a></li>
                        </ul>
                    </div>
                    <a routerLink="#" class="link-btn"><i class="icofont-link"></i></a>
                    <a href="assets/img/work-img6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h3>What our client are saying</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <div class="client-info">
                    <div class="img">
                        <img src="assets/img/client-avatar1.jpg" alt="client">
                    </div>
                    <h4>Josh Buttler</h4>
                    <span>Web Developer</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <i class="icofont-quote-right"></i>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <div class="img">
                        <img src="assets/img/client-avatar2.jpg" alt="client">
                    </div>
                    <h4>David Andy</h4>
                    <span>Web Designer</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <i class="icofont-quote-right"></i>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <div class="img">
                        <img src="assets/img/client-avatar3.jpg" alt="client">
                    </div>
                    <h4>Lina D'Souja</h4>
                    <span>QA Developer</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <i class="icofont-quote-right"></i>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <div class="img">
                        <img src="assets/img/client-avatar1.jpg" alt="client">
                    </div>
                    <h4>Sarah Taylor</h4>
                    <span>Team Lead</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <i class="icofont-quote-right"></i>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <div class="img">
                        <img src="assets/img/client-avatar2.jpg" alt="client">
                    </div>
                    <h4>John Smith</h4>
                    <span>Web Developer</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <i class="icofont-quote-right"></i>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <div class="img">
                        <img src="assets/img/client-avatar3.jpg" alt="client">
                    </div>
                    <h4>Eva Lucy</h4>
                    <span>PHP Developer</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <i class="icofont-quote-right"></i>
            </div>
        </div>
    </div>
    <div class="rocket fadeInUp wow slow"><img src="assets/img/rocket.png" alt="rocket"></div>
    <div class="vector"><img src="assets/img/vector-bg.png" alt="vector"></div>
</section>

<section class="funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-users-alt-5"></i>
                    <p>Active Clients:</p>
                    <h3><span class="odometer" data-count="2150">00</span><span class="sign">+</span></h3>
                </div>
            </div>
            
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-rocket-alt-2"></i>
                    <p>Success Rate:</p>
                    <h3><span class="odometer" data-count="86">00</span><span class="sign">%</span></h3>
                </div>
            </div>
            
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-laptop-alt"></i>
                    <p>Projects Done:</p>
                    <h3><span class="odometer" data-count="550">00</span><span class="sign">+</span></h3>
                </div>
            </div>
            
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-award"></i>
                    <p>Awwards:</p>
                    <h3><span class="odometer" data-count="35">00</span><span class="sign">+</span></h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="section-title">
            <span>Pricing Table</span>
            <h3>Not any hidden charges, choose your plan</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
            <a routerLink="/about" class="read-more-btn">View More About Us</a>
        </div>
        
        <div class="tab-slider-nav">
            <ul class="tab-slider-tabs">
                <li class="tab-slider-trigger active" rel="monthly">Monthly</li>
                <li class="tab-slider-trigger" rel="yearly">Yearly</li>
            </ul>
        </div>
    
        <div class="tab-slider-container">
            <div id="monthly" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="pricingTable">
                            <div class="pricingTable-header">
                                <h3 class="title">Basic</h3>
                                <div class="price-value">$59.99 <span>/per month</span></div>
                            </div>
                            <ul class="pricing-content">
                                <li>Managment & Marketing</li>
                                <li>SEO Optimization</li>
                                <li>25 Analytics Campaign</li>
                                <li>1,300 Change Keywords</li>
                                <li>25 Social Media Reviews</li>
                                <li>1 Free Optimization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn btn-primary">Buy Now</a>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="pricingTable">
                            <div class="pricingTable-header">
                                <h3 class="title">Ultra</h3>
                                <div class="price-value">$79.99 <span>/per month</span></div>
                            </div>
                            <ul class="pricing-content">
                                <li>Managment & Marketing</li>
                                <li>SEO Optimization</li>
                                <li>25 Analytics Campaign</li>
                                <li>1,300 Change Keywords</li>
                                <li>25 Social Media Reviews</li>
                                <li>1 Free Optimization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn btn-primary">Buy Now</a>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div class="pricingTable">
                            <div class="pricingTable-header">
                                <h3 class="title">Pro</h3>
                                <div class="price-value">$99.99 <span>/per month</span></div>
                            </div>
                            <ul class="pricing-content">
                                <li>Managment & Marketing</li>
                                <li>SEO Optimization</li>
                                <li>25 Analytics Campaign</li>
                                <li>1,300 Change Keywords</li>
                                <li>25 Social Media Reviews</li>
                                <li>1 Free Optimization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn btn-primary">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="yearly" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="pricingTable">
                            <div class="pricingTable-header">
                                <h3 class="title">Basic</h3>
                                <div class="price-value">$59.99 <span>/per year</span></div>
                            </div>
                            <ul class="pricing-content">
                                <li>Managment & Marketing</li>
                                <li>SEO Optimization</li>
                                <li>25 Analytics Campaign</li>
                                <li>1,300 Change Keywords</li>
                                <li>25 Social Media Reviews</li>
                                <li>1 Free Optimization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn btn-primary">Buy Now</a>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="pricingTable">
                            <div class="pricingTable-header">
                                <h3 class="title">Ultra</h3>
                                <div class="price-value">$79.99 <span>/per year</span></div>
                            </div>
                            <ul class="pricing-content">
                                <li>Managment & Marketing</li>
                                <li>SEO Optimization</li>
                                <li>25 Analytics Campaign</li>
                                <li>1,300 Change Keywords</li>
                                <li>25 Social Media Reviews</li>
                                <li>1 Free Optimization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn btn-primary">Buy Now</a>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0 offset-md-3">
                        <div class="pricingTable">
                            <div class="pricingTable-header">
                                <h3 class="title">Pro</h3>
                                <div class="price-value">$99.99 <span>/per year</span></div>
                            </div>
                            <ul class="pricing-content">
                                <li>Managment & Marketing</li>
                                <li>SEO Optimization</li>
                                <li>25 Analytics Campaign</li>
                                <li>1,300 Change Keywords</li>
                                <li>25 Social Media Reviews</li>
                                <li>1 Free Optimization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#" class="btn btn-primary">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="rocket fadeInUp wow slow"><img src="assets/img/rocket.png" alt="rocket"></div>
</section>

<div class="partner-area ptb-100 pt-0 bg-fbf9f8">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-1.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-2.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-3.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-4.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-5.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-6.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-1.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-2.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-3.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-4.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-5.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner-6.png" alt="partner"></a>
            </div>
        </div>
    </div>
    <div class="vector"><img src="assets/img/vector-bg.png" alt="vector"></div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our News</span>
            <h3>Latest Blog Posts</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
            <a routerLink="#" class="read-more-btn">View More News.</a>
        </div>
        
        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-item bg-1">
                <span>Business & Tech</span>
                <h3><a routerLink="#">How to Market Your Marketing Agency in 2020</a></h3>
                <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                <a routerLink="#" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
            </div>
        
            <div class="single-blog-item bg-2">
                <span>Testing & Tools</span>
                <h3><a routerLink="#">15 Testing Tools for Optimizing Your Site in 2020</a></h3>
                <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                <a routerLink="#" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
            </div>
        
            <div class="single-blog-item bg-3">
                <span>User & Experience</span>
                <h3><a routerLink="#">12 Tried-and-True Ways to Improve User Experience</a></h3>
                <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                <a routerLink="#" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
            </div>
        
            <div class="single-blog-item bg-4">
                <span>Apps & Trend</span>
                <h3><a routerLink="#">The 13 Best Time Tracking Apps of 2020</a></h3>
                <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                <a routerLink="#" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
            </div>
        
            <div class="single-blog-item bg-5">
                <span>Business & Document</span>
                <h3><a routerLink="#">How to Document Processes to Scale Your Agency Faster</a></h3>
                <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                <a routerLink="#" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>