<div class="page-title animatedBackground">
  <div class="container">
    <h3>Project Details</h3>
    <ul>
      <li><a routerLink="/">Home</a></li>
      <li class="dot-divider"></li>
      <li>Project Details</li>
    </ul>
  </div>
  <div class="bg-pattern">
    <img src="assets/img/bg-pattern-2.png" alt="pattern" />
  </div>
</div>

<section class="project-details-area ptb-100">
  <div class="container">
    <div class="project-img">
      <img [src]="imgUrl" alt="photo" />
    </div>
    <div class="project-details">
      <div class="row">
        <div class="col-lg-8 col-md-7">
          <h3>{{ title }}</h3>
          <span [innerHTML]="description"></span>
          <!-- <p>

          </p>
          <p>
            Consectetur adipisicing elit. Totam mollitia incidunt vero
            cupiditate obcaecati iusto tempora unde! Numquam officiis, quae
            adipisci quam laudantium nulla modi. adipisci quam laudantium nulla
            modi. Totam mollitia incidunt vero cupiditate obcaecati
          </p> -->
          <p>
            Website url: <a [href]="websiteUrl">{{ websiteUrl }}</a>
          </p>
          <p *ngIf="appUrl">
            Progressive Web App url: <a [href]="appUrl">{{ appUrl }}</a>
          </p>
          <p *ngIf="playStoreUrl">
            Android app url: <a [href]="playStoreUrl">{{ playStoreUrl }}</a>
          </p>
          <!-- <ul class="features">
            <li>Design must be functional</li>
            <li>Aenean pellentesque elit vitae</li>
            <li>Futionality must translated into</li>
            <li>Phasellus eget purus id felis</li>
            <li>Design must be functional</li>
          </ul> -->
        </div>

        <div class="col-lg-4 col-md-5">
          <div class="project-info">
            <ul>
              <li>
                <i class="icofont-ui-user"></i>
                <span>Client</span>
                {{ title }}
              </li>

              <li>
                <i class="icofont-laptop-alt"></i>
                <span>Created by</span>
                SR Development
              </li>

              <li>
                <i class="icofont-checked"></i>
                <span>Project Completed</span>
                {{ completed }}
              </li>

              <li>
                <i class="icofont-map-pins"></i>
                <span>Location</span>
                {{ location }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscribe></app-subscribe>
