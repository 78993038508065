<div class="page-title animatedBackground">
    <div class="container">
        <h3>Blog</h3>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li class="dot-divider"></li>
            <li>Blog</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-1">
                            <span>Business & Tech</span>
                            <h3><a routerLink="/blog-details">How to Market Your Marketing Agency in 2020</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-2">
                            <span>Testing & Tools</span>
                            <h3><a routerLink="/blog-details">15 Testing Tools for Optimizing Your Site in 2020</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-3">
                            <span>User & Experience</span>
                            <h3><a routerLink="/blog-details">12 Tried-and-True Ways to Improve User Experience</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-4">
                            <span>Apps & Trend</span>
                            <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2020</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-5">
                            <span>Business & Document</span>
                            <h3><a routerLink="/blog-details">How to Document Processes to Scale Your Agency Faster</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-1">
                            <span>Business & Tech</span>
                            <h3><a routerLink="/blog-details">How to Market Your Marketing Agency in 2020</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-2">
                            <span>Testing & Tools</span>
                            <h3><a routerLink="/blog-details">15 Testing Tools for Optimizing Your Site in 2020</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item bg-3">
                            <span>User & Experience</span>
                            <h3><a routerLink="/blog-details">12 Tried-and-True Ways to Improve User Experience</a></h3>
                            <p>Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                            <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="icofont-stylish-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <nav aria-label="navigation">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-left"></i></a></li>
                                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-right"></i></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="side-bar mb-0">
                    <div class="widget-box search-box">
                        <form>
                            <input type="text" placeholder="Search here..." class="form-control">
                            <button type="submit"><i class="icofont-search-2"></i></button>
                        </form>
                    </div>
                    <div class="widget-box categories-box">
                        <h3 class="title">Categories</h3>
                        <ul>
                            <li><a href="#"><i class="icofont-dotted-right"></i> Design Tutorials (5)</a></li>
                            <li><a href="#"><i class="icofont-dotted-right"></i> Branding (8)</a></li>
                            <li><a href="#"><i class="icofont-dotted-right"></i> Arts and Entertainment (4)</a></li>
                            <li><a href="#"><i class="icofont-dotted-right"></i> Blog Post (6)</a></li>
                            <li><a href="#"><i class="icofont-dotted-right"></i> Seo Analysis (3)</a></li>
                            <li><a href="#"><i class="icofont-dotted-right"></i> Online Marketing (15)</a></li>
                            <li><a href="#"><i class="icofont-dotted-right"></i> Email Marketing (12)</a></li>
                        </ul>
                    </div>
                    <div class="widget-box tags-box">
                        <h3 class="title">Tags</h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Branding</a></li>
                            <li><a href="#">Entertainment</a></li>
                            <li><a href="#">Arts</a></li>
                            <li><a href="#">Blog Post</a></li>
                            <li><a href="#">Seo Analysis</a></li>
                            <li><a href="#">Marketing</a></li>
                            <li><a href="#">Email Marketing</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>