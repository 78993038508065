<header class="top-header-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5">
        <ul class="social-links">
          <li>Follow Us:</li>
          <li>
            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
          </li>
          <li>
            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
          </li>
          <li>
            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
          </li>
        </ul>
      </div>

      <div class="col-lg-7 col-md-7">
        <ul class="header-info">
          <li><a routerLink="#">About</a></li>
          <li><a routerLink="#">FAQ</a></li>
          <li><a routerLink="#">Privacy Policy</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
