<div class="page-title animatedBackground">
  <div class="container">
    <h3>Project</h3>
    <ul>
      <li><a routerLink="/">Home</a></li>
      <li class="dot-divider"></li>
      <li>Project</li>
    </ul>
  </div>
  <div class="bg-pattern">
    <img src="assets/img/bg-pattern-2.png" alt="pattern" />
  </div>
</div>

<section class="project-area ptb-100">
  <div class="container">
    <div class="shorting-menu text-center">
      <button class="filter" data-filter="all">All</button>
      <button class="filter" data-filter=".website">Website</button>
      <button class="filter" data-filter=".mobile-app">Mobile app</button>
      <button class="filter" data-filter=".webshop">Webshop</button>
      <button class="filter" data-filter=".branding">Branding</button>
    </div>
    <div class="shorting">
      <div class="row m-0">
        <!-- Simple Fix -->
        <div class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img11.png" alt="work-img" />
            <div class="work-content">
              <h3>Simple Fix</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/mijn-menu"
              [state]="{
                title: 'Z&PC DIO - Zwem & Waterpolo club',
                completed: '1 Januari 2021',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-mijn-menu.jpg',
                description:
                  '<p>Mijn Menu is een online platform dat enerzijds fungeert als een marketing outlet voor restaurants. Anderzijds als een functionele horeca app waardoor gebruikers kunnen reserveren, bestellen, rekening delen, betalen en meer.</p> <p>De Mijn Menu app is ontwikkeld zodat consumenten in de omgeving kunnen oriënteren qua horecagelegenheden maar ook het menu inzien. Daarnaast kunnen gasten bestellen & betalen binnen de app op hun eigen telefoon. Anderzijds is Mijn Menu de trouwe werknemer waar een horecagelegenheid van droomt. Mijn Menu kan uw personeel ondersteunen door taken van serveerders te digitaliseren en in de handen van uw consument te leggen. </p>',
                website: 'https://dioweb.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img11.png" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <!-- Pretty Paws -->
        <div class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img10.png" alt="work-img" />
            <div class="work-content">
              <h3>Pretty Paws</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/mijn-menu"
              [state]="{
                title: 'Z&PC DIO - Zwem & Waterpolo club',
                completed: '1 Januari 2021',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-mijn-menu.jpg',
                description:
                  '<p>Mijn Menu is een online platform dat enerzijds fungeert als een marketing outlet voor restaurants. Anderzijds als een functionele horeca app waardoor gebruikers kunnen reserveren, bestellen, rekening delen, betalen en meer.</p> <p>De Mijn Menu app is ontwikkeld zodat consumenten in de omgeving kunnen oriënteren qua horecagelegenheden maar ook het menu inzien. Daarnaast kunnen gasten bestellen & betalen binnen de app op hun eigen telefoon. Anderzijds is Mijn Menu de trouwe werknemer waar een horecagelegenheid van droomt. Mijn Menu kan uw personeel ondersteunen door taken van serveerders te digitaliseren en in de handen van uw consument te leggen. </p>',
                website: 'https://dioweb.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img10.png" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <!-- YourFoodSpot -->
        <div class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img9.png" alt="work-img" />
            <div class="work-content">
              <h3>YourFoodSpot</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/mijn-menu"
              [state]="{
                title: 'Z&PC DIO - Zwem & Waterpolo club',
                completed: '1 Januari 2021',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-mijn-menu.jpg',
                description:
                  '<p>Mijn Menu is een online platform dat enerzijds fungeert als een marketing outlet voor restaurants. Anderzijds als een functionele horeca app waardoor gebruikers kunnen reserveren, bestellen, rekening delen, betalen en meer.</p> <p>De Mijn Menu app is ontwikkeld zodat consumenten in de omgeving kunnen oriënteren qua horecagelegenheden maar ook het menu inzien. Daarnaast kunnen gasten bestellen & betalen binnen de app op hun eigen telefoon. Anderzijds is Mijn Menu de trouwe werknemer waar een horecagelegenheid van droomt. Mijn Menu kan uw personeel ondersteunen door taken van serveerders te digitaliseren en in de handen van uw consument te leggen. </p>',
                website: 'https://dioweb.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img9.png" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <!-- Investly -->
        <div class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img8.png" alt="work-img" />
            <div class="work-content">
              <h3>Investly</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/mijn-menu"
              [state]="{
                title: 'Z&PC DIO - Zwem & Waterpolo club',
                completed: '1 Januari 2021',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-mijn-menu.jpg',
                description:
                  '<p>Mijn Menu is een online platform dat enerzijds fungeert als een marketing outlet voor restaurants. Anderzijds als een functionele horeca app waardoor gebruikers kunnen reserveren, bestellen, rekening delen, betalen en meer.</p> <p>De Mijn Menu app is ontwikkeld zodat consumenten in de omgeving kunnen oriënteren qua horecagelegenheden maar ook het menu inzien. Daarnaast kunnen gasten bestellen & betalen binnen de app op hun eigen telefoon. Anderzijds is Mijn Menu de trouwe werknemer waar een horecagelegenheid van droomt. Mijn Menu kan uw personeel ondersteunen door taken van serveerders te digitaliseren en in de handen van uw consument te leggen. </p>',
                website: 'https://dioweb.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img8.png" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <!-- Dio  -->
        <div class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img7.png" alt="work-img" />
            <div class="work-content">
              <h3>Z&PC DIO - Zwem & Waterpolo club</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/mijn-menu"
              [state]="{
                title: 'Z&PC DIO - Zwem & Waterpolo club',
                completed: '1 Januari 2021',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-mijn-menu.jpg',
                description:
                  '<p>Mijn Menu is een online platform dat enerzijds fungeert als een marketing outlet voor restaurants. Anderzijds als een functionele horeca app waardoor gebruikers kunnen reserveren, bestellen, rekening delen, betalen en meer.</p> <p>De Mijn Menu app is ontwikkeld zodat consumenten in de omgeving kunnen oriënteren qua horecagelegenheden maar ook het menu inzien. Daarnaast kunnen gasten bestellen & betalen binnen de app op hun eigen telefoon. Anderzijds is Mijn Menu de trouwe werknemer waar een horecagelegenheid van droomt. Mijn Menu kan uw personeel ondersteunen door taken van serveerders te digitaliseren en in de handen van uw consument te leggen. </p>',
                website: 'https://dioweb.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img1.jpg" alt="work-img" />
            <div class="work-content">
              <h3>Mijn Menu</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Mobile app</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/mijn-menu"
              [state]="{
                title: 'Mijn Menu',
                completed: '20 Augustus 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-mijn-menu.jpg',
                description:
                  '<p>Mijn Menu is een online platform dat enerzijds fungeert als een marketing outlet voor restaurants. Anderzijds als een functionele horeca app waardoor gebruikers kunnen reserveren, bestellen, rekening delen, betalen en meer.</p> <p>De Mijn Menu app is ontwikkeld zodat consumenten in de omgeving kunnen oriënteren qua horecagelegenheden maar ook het menu inzien. Daarnaast kunnen gasten bestellen & betalen binnen de app op hun eigen telefoon. Anderzijds is Mijn Menu de trouwe werknemer waar een horecagelegenheid van droomt. Mijn Menu kan uw personeel ondersteunen door taken van serveerders te digitaliseren en in de handen van uw consument te leggen. </p>',
                website: 'https://mijn-menu.nl/',
                app: 'http://app.mijn-menu.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img5.jpg" alt="work-img" />
            <div class="work-content">
              <h3>PhysiGo</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Mobile app</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>

            <a
              routerLink="/projects-details/physigo"
              [state]="{
                title: 'PhysiGo',
                completed: '11 Augustus 2018',
                location: 'Breda, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-physigo.jpg',
                description:
                  '<p>Creëer een oplossing welke ondersteunend, motiverend maar vooral makkelijk toegankelijk is om de fysiotherapie trouw in de thuissituatie voor ten minste de duur van de behandelfase te verhogen. - Physi Go..</p> <p>De Work Sup bevat Ingrediënten waarvan wetenschappelijk is aangetoond dat ze je in de ideale mentale staat brengen om dingen gedaan te krijgen – zonder onrustig gevoel of crash. </p>',
                website: 'http://physigo.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website webshop branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img2.jpg" alt="work-img" />
            <div class="work-content">
              <h3>My Work Sup</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Webshop</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/my-work-sup"
              [state]="{
                title: 'My Work Sup',
                completed: '11 Maart 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-my-work-sup.jpg',
                description:
                  '<p>My Work Sup is een supplement die is bedacht door ondernemers voor ondernemers. Het is een concentratie supplement welke de focus verhoogt en daarbij.</p> <p>De Work Sup bevat Ingrediënten waarvan wetenschappelijk is aangetoond dat ze je in de ideale mentale staat brengen om dingen gedaan te krijgen – zonder onrustig gevoel of crash. </p>',
                website: 'https://myworksup.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website webshop branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img3.jpg" alt="work-img" />
            <div class="work-content">
              <h3>My Fashion Sup</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Webshop</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/my-fashion-sup"
              [state]="{
                title: 'My Fashion Sup',
                completed: '11 April 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-my-fashion-sup.jpg',
                description:
                  '<p>My Fashion Sup, jouw totale mode oplossing. Ongeacht jouw vibe of smaak; casual, chique of elegant My Fashion Sup bezorgt jou de nieuwste mode trends van het aankomende jaar. Ook zo benieuwd welke mode stijlen de hollywood sterren in hun eigen collectie hebben? Of simpelweg wat gestrest over hoe jij je outfit het aankomende feestje kan rocken? Geen zorgen! </p> <p>Alles wat je hartje begeert, de nieuwste mode zoals unieke trendy tassen, oversized stylie zonnebrillen of zelfs unieke sierraden, wij hebben het allemaal voor jou samengebracht op een plek. My Fashion Sup for all things fashion! Happy shopping ladies! </p>',
                website: 'https://myfashionsup.com/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img4.jpg" alt="work-img" />
            <div class="work-content">
              <h3>Barbershop Etten-Leur</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/barbershop-etten-leur"
              [state]="{
                title: 'Barbershop Etten-Leur',
                completed: '1 Mei 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-barbershop-etten-leur.jpg',
                description:
                  '<p>BarberShop Etten-Leur staat voor kwaliteit, service en gezelligheid. Tradities van toen met de technieken van nu. Wilt u vakkundig geschoren of geknipt worden? Dan bent u van harte welkom! </p>'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img6.jpg" alt="work-img" />
            <div class="work-content">
              <h3>Healhty Food Finder</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a routerLink="/projects-details" class="link-btn"><i class="icofont-link"></i></a>
            <a href="assets/img/work-img6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscribe></app-subscribe>
