<div class="page-title animatedBackground">
  <div class="container">
    <h3>Prijzen</h3>
    <ul>
      <li><a routerLink="/">Home</a></li>
      <li class="dot-divider"></li>
      <li>Prijzen</li>
    </ul>
  </div>
  <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern" /></div>
</div>

<section class="pricing-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="tab-slider-nav">
      <h3 class="mb-4">Onderhoudspakket</h3>
      <ul class="tab-slider-tabs">
        <li class="tab-slider-trigger active" rel="monthly">Monthly</li>
        <li class="tab-slider-trigger" rel="yearly">Yearly</li>
      </ul>
    </div>
    <div class="tab-slider-container">
      <div id="monthly" class="tab-slider-body">
        <div class="row">
          <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Basic website</h3>
                <div class="price-value">499 <span> excl. BTW</span></div>
              </div>
              <ul class="pricing-content">
                <li>Professioneel design</li>
                <li>SEO Optimalisatie</li>
                <li>Social media integratie</li>
                <li>100% Responsive</li>
                <li>Hosting pakket (inc domeinnaam)</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Website & CMS</h3>
                <div class="price-value">749 <span> excl. BTW</span></div>
              </div>
              <ul class="pricing-content">
                <li>Professioneel design</li>
                <li>SEO Optimalisatie</li>
                <li>Social media integratie</li>
                <li>100% Responsive</li>
                <li>Hosting pakket (inc domeinnaam)</li>
                <li>24/7 Support</li>
                <li>Gebruiksvriendelijk CMS</li>

                <a
                  *ngIf="collapsed"
                  class="orange-text"
                  data-toggle="collapse"
                  data-target="#collapsable-list"
                  (click)="collapsed = false"
                >
                  Uitklappen<i class="icofont-simple-down"></i>
                </a>
                <div id="collapsable-list" class="collapse mb-3">
                  <li>Begeleiding/uitleg CMS</li>
                  <li>Nieuwsberichten zelf toevoegen</li>
                  <li>Afbeeldingen zelf toevoegen</li>
                  <li>(Youtube) filmpjes zelf toevoegen</li>
                  <li>Pagina's zelf aanpassen</li>
                  <li>Bezoekers statistieken</li>
                </div>
                <a
                  *ngIf="!collapsed"
                  class="orange-text"
                  data-toggle="collapse"
                  data-target="#collapsable-list"
                  (click)="collapsed = true"
                >
                  Inklappen<i class="icofont-simple-up"></i>
                </a>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-md-6 offset-sm-3 offset-lg-0 offset-md-3">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Complete webshop</h3>
                <div class="price-value">999 <span> excl. BTW</span></div>
              </div>
              <ul class="pricing-content">
                <li>Professioneel design</li>
                <li>SEO Optimalisatie</li>
                <li>Social media integratie</li>
                <li>100% Responsive</li>
                <li>Hosting pakket (inc domeinnaam)</li>
                <li>24/7 Support</li>
                <li>Gebruiksvriendelijk CMS</li>
                <a
                  *ngIf="collapsed"
                  class="orange-text"
                  data-toggle="collapse"
                  data-target="#collapsable-list"
                  (click)="collapsed = false"
                >
                  Uitklappen<i class="icofont-simple-down"></i>
                </a>
                <div id="collapsable-list" class="collapse mb-3">
                  <li>Begeleiding/uitleg CMS</li>
                  <li>Nieuwsberichten zelf toevoegen</li>
                  <li>Afbeeldingen zelf toevoegen</li>
                  <li>(Youtube) filmpjes zelf toevoegen</li>
                  <li>Pagina's zelf aanpassen</li>
                  <li>Bezoekers statistieken</li>
                  <li>Complete webwinkel</li>
                  <li>iDeal betaling systeem</li>
                  <li>Facturen genereren</li>
                  <li>Voorraad beheer</li>
                </div>
                <a
                  *ngIf="!collapsed"
                  class="orange-text"
                  data-toggle="collapse"
                  data-target="#collapsable-list"
                  (click)="collapsed = true"
                >
                  Inklappen<i class="icofont-simple-up"></i>
                </a>
              </ul>

              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>
        </div>
      </div>

      <div id="yearly" class="tab-slider-body">
        <div class="row">
          <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Basic</h3>
                <div class="price-value">$59.99 <span>/per year</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Ultra</h3>
                <div class="price-value">$79.99 <span>/per year</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-md-6 offset-sm-3 offset-lg-0 offset-md-3">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Complete webshop</h3>
                <div class="price-value">$99.99 <span>/per year</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rocket fadeInUp wow slow"><img src="assets/img/rocket.png" alt="rocket" /></div>
</section>

<!-- <section class="pricing-area ptb-100">
  <div class="container">
    <div class="row m-0">
      <div class="col-lg-4 col-md-6 col-sm-6 p-0">
        <div class="pricing-plan">
          <h3>Enterprise<span>$59</span></h3>
          <ul>
            <li>Managment & Marketing</li>
            <li>SEO Optimization</li>
            <li>25 Analytics Campaign</li>
            <li>1,300 Change Keywords</li>
            <li>25 Social Media Reviews</li>
            <li>1 Free Optimization</li>
            <li>24/7 Support</li>
          </ul>
          <a class="btn btn-primary" routerLink="#">Buy Now</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6 p-0">
        <div class="pricing-plan" id="most-popular">
          <h3>Professional<span>$29</span></h3>
          <ul>
            <li>Managment & Marketing</li>
            <li>SEO Optimization</li>
            <li>25 Analytics Campaign</li>
            <li>1,300 Change Keywords</li>
            <li>25 Social Media Reviews</li>
            <li>1 Free Optimization</li>
            <li>24/7 Support</li>
          </ul>
          <a class="btn btn-primary" routerLink="#">Buy Now</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6 p-0 offset-sm-3 offset-lg-0 offset-md-3">
        <div class="pricing-plan">
          <h3>Standard<span>$17</span></h3>
          <ul>
            <li>Managment & Marketing</li>
            <li>SEO Optimization</li>
            <li>25 Analytics Campaign</li>
            <li>1,300 Change Keywords</li>
            <li>25 Social Media Reviews</li>
            <li>1 Free Optimization</li>
            <li>24/7 Support</li>
          </ul>
          <a class="btn btn-primary" routerLink="#">Buy Now</a>
        </div>
      </div>
    </div>
  </div>
  <div class="rocket"><img src="assets/img/rocket.png" alt="rocket" /></div>
</section> -->

<app-subscribe></app-subscribe>
