<div class="page-title animatedBackground">
  <div class="container">
    <h3>{{ serviceTitle }}</h3>
    <ul>
      <li><a routerLink="/">Home</a></li>
      <li class="dot-divider"></li>
      <li>{{ serviceTitle }}</li>
    </ul>
  </div>
  <div class="bg-pattern">
    <img src="assets/img/bg-pattern-2.png" alt="pattern" />
  </div>
</div>

<section class="services-details-area ptb-100">
  <div class="container">
    <div *ngIf="serviceBlock1" class="services-details-overview">
      <div class="services-details-desc">
        <h3 *ngIf="serviceBlock1?.title">{{ serviceBlock1.title }}</h3>
        <div
          class="features-text"
          *ngFor="let feature of serviceBlock1.features"
        >
          <h4><i class="icofont-check-alt"></i> {{ feature }}</h4>
        </div>
      </div>

      <div class="services-details-image">
        <img [src]="serviceBlock1.img" alt="image" />
      </div>
    </div>
    <div *ngIf="showSecondBlock" class="services-details-overview">
      <div class="services-details-image">
        <img [src]="serviceBlock2.img" alt="image" />
      </div>

      <div class="services-details-desc">
        <h3 *ngIf="serviceBlock2?.title">{{ serviceBlock2.title }}</h3>
        <div
          class="features-text"
          *ngFor="let feature of serviceBlock2.features"
        >
          <h4><i class="icofont-check-alt"></i> {{ feature }}</h4>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscribe></app-subscribe>
