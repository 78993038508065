import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-projects-details',
  templateUrl: './projects-details.component.html',
  styleUrls: ['./projects-details.component.scss'],
})
export class ProjectsDetailsComponent implements OnInit {
  state$: Observable<object>;
  imgUrl: string;
  title: string;
  completed: string;
  location: string;
  description: string;
  websiteUrl: string;
  appUrl: string;
  playStoreUrl: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.state$ = this.route.paramMap
      .pipe(map(() => window.history.state));

    this.state$.subscribe((params) => {
      this.imgUrl = params['img'];
      this.title = params['title'];
      this.completed = params['completed'];
      this.location = params['location'];
      this.description = params['description'];
      this.websiteUrl = params['website'];
      this.appUrl = params['app'] ? params['app'] : null;
      this.playStoreUrl = params['playstore'] ? params['playstore'] : null;
    })
  }
}
