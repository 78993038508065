<footer class="footer-area bg-fbf9f8">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <div class="logo">
            <a routerLink="/"><img src="assets/img/logo.png" class="logo-w-100" alt="image" /></a>
          </div>
          <p>
            Wij zijn een full-service digital agency gespecialiseerd in het bedenken én realiseren van innovatieve
            digitale oplossingen.
          </p>
          <ul class="social-links">
            <li>
              <a href="#"><i class="icofont-facebook"></i></a>
            </li>
            <li>
              <a href="#"><i class="icofont-instagram"></i></a>
            </li>
            <li>
              <a href="#"><i class="icofont-linkedin"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Onze Diensten</h3>
          <ul class="services-list">
            <li><a href="/services-details/digital-design-and-branding">Digital design & Branding</a></li>
            <li><a href="/services-details/web-development">Web Development</a></li>
            <li><a href="/services-details/mobile-development">Mobile Development</a></li>
            <li><a href="#">E-commerce</a></li>
            <li><a href="#">Reports & Analytics</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Site map</h3>
          <ul class="usefull-links">
            <li><a href="/about">Over Ons</a></li>
            <li><a href="/services">Onze diensten</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/faq">FAQ</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Contact</h3>
          <ul class="contact-list">
            <li><span>Adres:</span> Liesbosweg 166, Etten-Leur, 4872NE, NL</li>
            <li><span>Website:</span> <a routerLink="/">sr-development.nl</a></li>
            <li>
              <span>Email:</span>
              <a href="mailto:hello@sr-development.nl">hello@sr-development.nl</a>
            </li>
            <!-- <li>
                            <span>Telefoon nummer:</span>
                            <a href="tel:+31648125756">+316 48 12 57 56</a>
                        </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <p>
            <i class="icofont-copyright"></i> 2020 SR Development. All Rights Reseverd by SR Development.
            <!-- <a href="https://envytheme.com/" target="_blank">EnvyTheme</a> -->
          </p>
        </div>

        <div class="col-lg-6 col-md-6">
          <ul>
            <li><a href="#">Terms & Conditions</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="go-top"><i class="icofont-stylish-up"></i></div>
