<div class="page-title animatedBackground">
  <div class="container">
    <h3>Contact</h3>
    <ul>
      <li><a routerLink="/">Home</a></li>
      <li class="dot-divider"></li>
      <li>Contact</li>
    </ul>
  </div>
  <div class="bg-pattern">
    <img src="assets/img/bg-pattern-2.png" alt="pattern" />
  </div>
</div>

<section class="contact-area ptb-100">
  <div class="container">
    <div class="row">
      <!-- <div class="col-lg-4 col-md-6">
        <div class="contact-box">
          <div class="icon">
            <i class="icofont-phone"></i>
          </div>
          <div class="content">
            <h3>Telefoon nummer</h3>
            <p><a href="tel:+31648125756">(+31) 648125756</a></p>
          </div>
        </div>
      </div> -->

      <div class="col-lg-6 col-md-6">
        <div class="contact-box">
          <div class="icon">
            <i class="icofont-envelope"></i>
          </div>
          <div class="content">
            <h3>E-mail</h3>
            <p>
              <a href="mailto:hello@sr-development.nl">hello@sr-development.nl </a>
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
        <div class="contact-box">
          <div class="icon">
            <i class="icofont-google-map"></i>
          </div>
          <div class="content">
            <h3>Locatie</h3>
            <p>Liesbosweg 166, Etten-Leur</p>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <app-map></app-map>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="contact-text">
          <h3>Get in Touch</h3>
          <p>
            U bent een stap dichterbij om uw idee om te zetten in een digitaal product. Vraagt u zich af hoe we aan uw
            volgende project beginnen?
            <a href="#">Ontdek uw volgende stappen</a>
          </p>
          <ul class="social-links">
            <li>
              <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <form id="contactForm">
          <div class="row">
            <div class="col-12 contact-text mb-4">
              <h3>Contact Formulier</h3>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Naam" />
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <input type="email" class="form-control" placeholder="Email" />
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Onderwerp" />
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <textarea
                  name="message"
                  class="form-control"
                  id="message"
                  cols="30"
                  rows="4"
                  placeholder="Je Bericht"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<app-subscribe></app-subscribe>
