<div class="main-banner">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-12">
            <div class="hero-content">
              <h1>
                Leading the way in Creative <span>Digital</span>
                <span>Agency</span>
              </h1>
              <p>
                Wij zijn een full-service web agency gespecialiseerd in het
                bedenken én realiseren van innovatieve digitale oplossingen.
              </p>
              <a routerLink="/contact" class="btn btn-primary">Get Started</a>
              <!-- <a
                href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                class="video-btn"
                >Play Now<i class="icofont-play-alt-3"></i
              ></a> -->
            </div>
          </div>

          <div class="hero-image">
            <img src="assets/img/hero-img.png" alt="hero-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="circle-pattern-1"></div>
</div>

<section class="about-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-brand-designfloat"></i>
          </div>
          <h3>Digital design & Branding</h3>
          <p>
            Ontwerpen van digitale interfaces en creëeren van herkenbare
            branding welke uw zakelijke waarden weerspiegelen.
          </p>
          <a
            routerLink="/services-details/digital-design-and-branding"
            class="read-more-btn"
            >Read More</a
          >
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-code"></i>
          </div>
          <h3>Web development</h3>
          <p>
            Het ontwikkelen van creatieve digitale oplossingen ondersteund door
            de nieuwste technologieën in de industrie.
          </p>
          <a
            routerLink="/services-details/web-development"
            class="read-more-btn"
            >Read More</a
          >
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-rocket-alt-1"></i>
          </div>
          <h3>Mobile development</h3>
          <p>
            Ios en android geen probleem! Via onze mobile first approach bieden
            wij de beste ervaring aan gebruikers.
          </p>
          <a
            routerLink="/services-details/mobile-development"
            class="read-more-btn"
            >Read More</a
          >
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 pt-4">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-cart"></i>
          </div>
          <h3>E-commerce</h3>
          <p>
            E-commerce is geëvolueerd om ervoor te zorgen dat producten
            gemakkelijker worden gevonden en gekocht. Wij zorgen er voor dat
            websitebezoekers worden omgezet in loyale klanten.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 pt-4">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-chart-arrows-axis"></i>
          </div>
          <h3>Reports & Analytics</h3>
          <p>
            Tegenwoordig is data niet meer weg te denken uit dit digitale
            tijdperk. Wij zorgen er voor dat u grip heeft op deze data door
            uitgebreiden analytics te genereren van uw website.
          </p>
        </div>
      </div>
    </div>

    <div class="about-inner-area">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="row about-image">
            <div class="col-6 col-lg-6 col-md-6">
              <div class="image">
                <img src="assets/img/about-img1.jpg" alt="about" />
              </div>
            </div>
            <div class="col-6 col-lg-6 col-md-6">
              <div class="image">
                <img src="assets/img/about-img2.jpg" alt="about" />
              </div>
            </div>
            <div class="col-6 col-lg-6 col-md-6">
              <div class="image mt-30">
                <img src="assets/img/about-img3.jpg" alt="about" />
              </div>
            </div>
            <div class="col-6 col-lg-6 col-md-6">
              <div class="image mt-30">
                <img src="assets/img/about-img4.jpg" alt="about" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="about-text">
            <span>Best Digital Agency</span>
            <h3>Wij helpen je business groeien!</h3>
            <p>
              We ontwerpen en ontwikkelen digitale producten en services van
              eerste idee tot professioneel online platform. Door een combinatie
              van ervaring, gezond verstand en een betrokken houding zetten we
              creatieve ideëen en business doelstellingen om in resultaten.
            </p>
            <p>
              Een applicatie is nooit een doel op zich, maar wordt ontwikkeld
              als medium om doelstellingen te behalen. Met geavanceerde tools
              focussen wij ons op de volledige customer journey waarbij "return
              on investment" centraal staat.
            </p>
            <p>
              We hebben diverse website en applicaties ontwikkeld voor
              toonaangevende merken van klein tot grote bedrijven in vele
              industrieën in Nederland.
            </p>
            <a routerLink="/about" class="read-more-btn">View More About us.</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rocket fadeInUp wow slow">
    <img src="assets/img/rocket.png" alt="rocket" />
  </div>
  <div class="vector"><img src="assets/img/vector-bg.png" alt="vector" /></div>
  <div class="circle-pattern-2"></div>
</section>

<!-- <section class="services-area bg ptb-100">
  <div class="container">
    <div class="section-title">
      <span>We work with you not for you</span>
      <h3>We’r provided best digital marketing services!</h3>
      <p>
        We enjoy adapting our strategies to offer every client the best
        solutions that are at the forefront of the industry.
      </p>
      <a routerLink="/services-1" class="read-more-btn">view more services</a>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services">
          <div class="icon">
            <i class="icofont-pie-chart"></i>
          </div>
          <h3>Strategy & Research</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor amet incididunt.
          </p>
          <a href="/services-details" class="read-more-btn">Read More</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services">
          <div class="icon">
            <i class="icofont-chart-arrows-axis"></i>
          </div>
          <h3>Reports & Analytics</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor amet incididunt.
          </p>
          <a href="/services-details" class="read-more-btn">Read More</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services">
          <div class="icon">
            <i class="icofont-rocket-alt-2"></i>
          </div>
          <h3>Managment & Marketing</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor amet incididunt.
          </p>
          <a href="/services-details" class="read-more-btn">Read More</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services">
          <div class="icon">
            <i class="icofont-chart-pie"></i>
          </div>
          <h3>Infographics Content</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor amet incididunt.
          </p>
          <a href="/services-details" class="read-more-btn">Read More</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services">
          <div class="icon">
            <i class="icofont-location-arrow"></i>
          </div>
          <h3>Media Promotion</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor amet incididunt.
          </p>
          <a href="/services-details" class="read-more-btn">Read More</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services">
          <div class="icon">
            <i class="icofont-live-support"></i>
          </div>
          <h3>Quick Support</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor amet incididunt.
          </p>
          <a href="/services-details" class="read-more-btn">Read More</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="analysis-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="analysis-form">
          <h3>Free SEO Analysis</h3>
          <form>
            <div class="form-group">
              <input type="text" placeholder="Name*" class="form-control" />
            </div>
            <div class="form-group">
              <input type="email" placeholder="Email*" class="form-control" />
            </div>
            <div class="form-group">
              <input type="text" placeholder="Phone*" class="form-control" />
            </div>
            <div class="form-group">
              <input type="text" placeholder="Website*" class="form-control" />
            </div>
            <div class="form-group">
              <input
                type="text"
                placeholder="Company Name*"
                class="form-control"
              />
            </div>
            <button type="submit" class="btn btn-primary">
              Get Free Report
            </button>
          </form>
        </div>
      </div>

      <div class="col-lg-7 col-md-12">
        <div class="analysis-text">
          <span>Free SEO Analysis</span>
          <h3>Be on the Top & Get More Traffic to Your Website</h3>
          <p>
            The following services explain how we approach SEO for a range of
            common purposes:
          </p>
          <ul>
            <li>
              If Google can’t crawl your site, it’s not going to rank – but that
              doesn’t mean avoiding Javascript.
            </li>
            <li>
              For businesses which sell products online and and improve their
              product listings in the search results.
            </li>
            <li>
              If you’re investing in a new website it’s important to ensure it’s
              built to succeed in the search results too.
            </li>
            <li>
              If Google can’t crawl your site, it’s not going to rank – but that
              doesn’t mean avoiding Javascript.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="project-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Recent Works</span>
      <h3>
        Wij vormen langdurige samenwerkingen om digitale producten van topklasse
        te kunnen maken!
      </h3>
      <p>
        Aantrekkelijke en goed gecodeerde interfaces worden als
        gebruiksvriendelijker ervaren, hierdoor maken we gebruikers geduldiger
        en loyaler
      </p>
      <a routerLink="/projects" class="read-more-btn">view more project</a>
    </div>
    <div class="shorting-menu">
      <button class="filter" data-filter="all">All</button>
      <button class="filter" data-filter=".website">Website</button>
      <button class="filter" data-filter=".mobile-app">Mobile app</button>
      <button class="filter" data-filter=".webshop">Webshop</button>
      <button class="filter" data-filter=".branding">Branding</button>
    </div>
    <div class="shorting">
      <div class="row m-0">
        <div
          class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0"
        >
          <div class="single-work">
            <img src="assets/img/work-img1.jpg" alt="work-img" />
            <div class="work-content">
              <h3>Mijn Menu</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Mobile app</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/mijn-menu"
              [queryParams]="{
                title: 'Mijn Menu',
                completed: '20 Augustus 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-mijn-menu.jpg',
                description:
                  '<p>Mijn Menu is een online platform dat enerzijds fungeert als een marketing outlet voor restaurants. Anderzijds als een functionele horeca app waardoor gebruikers kunnen reserveren, bestellen, rekening delen, betalen en meer.</p> <p>De Mijn Menu app is ontwikkeld zodat consumenten in de omgeving kunnen oriënteren qua horecagelegenheden maar ook het menu inzien. Daarnaast kunnen gasten bestellen & betalen binnen de app op hun eigen telefoon. Anderzijds is Mijn Menu de trouwe werknemer waar een horecagelegenheid van droomt. Mijn Menu kan uw personeel ondersteunen door taken van serveerders te digitaliseren en in de handen van uw consument te leggen. </p>',
                website: 'https://mijn-menu.nl/',
                app: 'http://app.mijn-menu.nl/',
                playstore:
                  'https://play.google.com/store/apps/details?id=nl.mijnmenu'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img1.jpg" class="popup-btn"
              ><i class="icofont-plus"></i
            ></a>
          </div>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app branding p-0"
        >
          <div class="single-work">
            <img src="assets/img/work-img5.jpg" alt="work-img" />
            <div class="work-content">
              <h3>PhysiGo</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Mobile app</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>

            <a
              routerLink="/projects-details/physigo"
              [queryParams]="{
                title: 'PhysiGo',
                completed: '11 Augustus 2018',
                location: 'Breda, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-physigo.jpg',
                description:
                  '<p>Creëer een oplossing welke ondersteunend, motiverend maar vooral makkelijk toegankelijk is om de fysiotherapie trouw in de thuissituatie voor ten minste de duur van de behandelfase te verhogen. - Physi Go..</p> <p>De Work Sup bevat Ingrediënten waarvan wetenschappelijk is aangetoond dat ze je in de ideale mentale staat brengen om dingen gedaan te krijgen – zonder onrustig gevoel of crash. </p>',
                website: 'http://physigo.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img5.jpg" class="popup-btn"
              ><i class="icofont-plus"></i
            ></a>
          </div>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app webshop branding p-0"
        >
          <div class="single-work">
            <img src="assets/img/work-img2.jpg" alt="work-img" />
            <div class="work-content">
              <h3>My Work Sup</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Webshop</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/my-work-sup"
              [queryParams]="{
                title: 'My Work Sup',
                completed: '11 Maart 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-my-work-sup.jpg',
                description:
                  '<p>My Work Sup is een supplement die is bedacht door ondernemers voor ondernemers. Het is een concentratie supplement welke de focus verhoogt en daarbij.</p> <p>De Work Sup bevat Ingrediënten waarvan wetenschappelijk is aangetoond dat ze je in de ideale mentale staat brengen om dingen gedaan te krijgen – zonder onrustig gevoel of crash. </p>',
                website: 'https://myworksup.nl/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img2.jpg" class="popup-btn"
              ><i class="icofont-plus"></i
            ></a>
          </div>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-6 mix website mobile-app webshop branding p-0"
        >
          <div class="single-work">
            <img src="assets/img/work-img3.jpg" alt="work-img" />
            <div class="work-content">
              <h3>My Fashion Sup</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Webshop</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/my-fashion-sup"
              [queryParams]="{
                title: 'My Fashion Sup',
                completed: '11 April 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-my-fashion-sup.jpg',
                description:
                  '<p>My Fashion Sup, jouw totale mode oplossing. Ongeacht jouw vibe of smaak; casual, chique of elegant My Fashion Sup bezorgt jou de nieuwste mode trends van het aankomende jaar. Ook zo benieuwd welke mode stijlen de hollywood sterren in hun eigen collectie hebben? Of simpelweg wat gestrest over hoe jij je outfit het aankomende feestje kan rocken? Geen zorgen! </p> <p>Alles wat je hartje begeert, de nieuwste mode zoals unieke trendy tassen, oversized stylie zonnebrillen of zelfs unieke sierraden, wij hebben het allemaal voor jou samengebracht op een plek. My Fashion Sup for all things fashion! Happy shopping ladies! </p>',
                website: 'https://myfashionsup.com/'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img3.jpg" class="popup-btn"
              ><i class="icofont-plus"></i
            ></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img4.jpg" alt="work-img" />
            <div class="work-content">
              <h3>Barbershop Etten-Leur</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a
              routerLink="/projects-details/barbershop-etten-leur"
              [queryParams]="{
                title: 'Barbershop Etten-Leur',
                completed: '1 Mei 2020',
                location: 'Etten-Leur, Noord-Brabant, Nederland',
                img: 'assets/img/project-details-barbershop-etten-leur.jpg',
                description:
                  '<p>BarberShop Etten-Leur staat voor kwaliteit, service en gezelligheid. Tradities van toen met de technieken van nu. Wilt u vakkundig geschoren of geknipt worden? Dan bent u van harte welkom! </p>'
              }"
              class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img4.jpg" class="popup-btn"
              ><i class="icofont-plus"></i
            ></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 mix website branding p-0">
          <div class="single-work">
            <img src="assets/img/work-img6.jpg" alt="work-img" />
            <div class="work-content">
              <h3>Healhty Food Finder</h3>
              <ul>
                <li><a href="#">Website</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Branding</a></li>
              </ul>
            </div>
            <a routerLink="/projects-details" class="link-btn"
              ><i class="icofont-link"></i
            ></a>
            <a href="assets/img/work-img6.jpg" class="popup-btn"
              ><i class="icofont-plus"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="testimonial-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="section-title">
      <span>Testimonial</span>
      <h3>Wat zeggen onze klanten?</h3>
      <p>
        We passen onze strategieën graag aan om elke klant de beste oplossingen
        te bieden! Dit alles met de nieuwste en snelste technieken in de markt.
      </p>
    </div>
    <div class="feedback-slides owl-carousel owl-theme">
      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar1.jpg" alt="client" />
          </div>
          <h4>Mick Geelen</h4>
          <span>Ondernemer</span>
        </div>
        <p>
          SR Development gaat zo veel verder dan andere web agencies. Met een
          unieke stijl en persoonlijkheid helpen ze mij in een ware
          groeitransformatie. Waar ik zocht naar een manier om mijn business
          online zichtbaar te maken, hebben zij mij geholpen om mijn business
          online schaalbaar te maken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar2.jpg" alt="client" />
          </div>
          <h4>Ronny</h4>
          <span>Barbier</span>
        </div>
        <p>
          SR Development is een zeer klantvriendelijk bedrijf die kwaliteit hoog
          in het vaandel heeft staan. De medewerkers zijn vriendelijk,
          behulpzaam en denken in creatieve oplossingen. Wat ik enorm waardeeer
          is dat ze meedenken en ideeën aandragen. De uitstraling van de website
          is precies wat ik zocht!
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar3.jpg" alt="client" />
          </div>
          <h4>Lina Souja</h4>
          <span>Owner Social Media Marketing Agency</span>
        </div>
        <p>
          Het is een feest om met SR Development samen te werken. De supernerds
          die ze in huis hebben zijn echt goed. Ze bouwen slimme platformen, en
          denken altijd met je mee. Het zijn fijne mensen die oprecht vanuit hun
          hart werken. Kortom een no brainer, je zult er geen spijt van krijgen
          om met SR Development te werken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar1.jpg" alt="client" />
          </div>
          <h4>Mick Geelen</h4>
          <span>Ondernemer</span>
        </div>
        <p>
          SR Development gaat zo veel verder dan andere web agencies. Met een
          unieke stijl en persoonlijkheid helpen ze mij in een ware
          groeitransformatie. Waar ik zocht naar een manier om mijn business
          online zichtbaar te maken, hebben zij mij geholpen om mijn business
          online schaalbaar te maken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar2.jpg" alt="client" />
          </div>
          <h4>Ronny</h4>
          <span>Barbier</span>
        </div>
        <p>
          SR Development is een zeer klantvriendelijk bedrijf die kwaliteit hoog
          in het vaandel heeft staan. De medewerkers zijn vriendelijk,
          behulpzaam en denken in creatieve oplossingen. Wat ik enorm waardeeer
          is dat ze meedenken en ideeën aandragen. De uitstraling van de website
          is precies wat ik zocht!
        </p>
        <i class="icofont-quote-right"></i>
      </div>

      <div class="single-feedback">
        <div class="client-info">
          <div class="img">
            <img src="assets/img/client-avatar3.jpg" alt="client" />
          </div>
          <h4>Lina Souja</h4>
          <span>Owner Social Media Marketing Agency</span>
        </div>
        <p>
          Het is een feest om met SR Development samen te werken. De supernerds
          die ze in huis hebben zijn echt goed. Ze bouwen slimme platformen, en
          denken altijd met je mee. Het zijn fijne mensen die oprecht vanuit hun
          hart werken. Kortom een no brainer, je zult er geen spijt van krijgen
          om met SR Development te werken.
        </p>
        <i class="icofont-quote-right"></i>
      </div>
    </div>
  </div>
  <div class="rocket fadeInUp wow slow">
    <img src="assets/img/rocket.png" alt="rocket" />
  </div>
  <div class="vector"><img src="assets/img/vector-bg.png" alt="vector" /></div>
</section>

<section class="funfacts-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-6 col-lg-4 col-sm-4 col-md-4">
        <div class="fun-fact">
          <i class="icofont-users-alt-5"></i>
          <p>Active Clients:</p>
          <h3>
            <span class="odometer" data-count="10">00</span
            ><span class="sign">+</span>
          </h3>
        </div>
      </div>

      <div class="col-6 col-lg-4 col-sm-4 col-md-4">
        <div class="fun-fact">
          <i class="icofont-rocket-alt-2"></i>
          <p>Success Rate:</p>
          <h3>
            <span class="odometer" data-count="100">00</span
            ><span class="sign">%</span>
          </h3>
        </div>
      </div>

      <div class="col-6 col-lg-4 col-sm-4 col-md-4">
        <div class="fun-fact">
          <i class="icofont-laptop-alt"></i>
          <p>Projects Done:</p>
          <h3>
            <span class="odometer" data-count="20">00</span
            ><span class="sign">+</span>
          </h3>
        </div>
      </div>

      <!-- <div class="col-6 col-lg-3 col-sm-3 col-md-3">
        <div class="fun-fact">
          <i class="icofont-award"></i>
          <p>Awwards:</p>
          <h3>
            <span class="odometer" data-count="35">00</span
            ><span class="sign">+</span>
          </h3>
        </div>
      </div> -->
    </div>
  </div>
</section>

<!-- <section class="pricing-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="section-title">
      <span>Pricing Table</span>
      <h3>Not any hidden charges, choose your plan</h3>
      <p>
        We enjoy adapting our strategies to offer every client the best
        solutions that are at the forefront of the industry.
      </p>
      <a routerLink="/about" class="read-more-btn">View More About Us</a>
    </div>

    <div class="tab-slider-nav">
      <ul class="tab-slider-tabs">
        <li class="tab-slider-trigger active" rel="monthly">Monthly</li>
        <li class="tab-slider-trigger" rel="yearly">Yearly</li>
      </ul>
    </div>

    <div class="tab-slider-container">
      <div id="monthly" class="tab-slider-body">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Basic</h3>
                <div class="price-value">$59.99 <span>/per month</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Ultra</h3>
                <div class="price-value">$79.99 <span>/per month</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3"
          >
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Pro</h3>
                <div class="price-value">$99.99 <span>/per month</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>
        </div>
      </div>

      <div id="yearly" class="tab-slider-body">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Basic</h3>
                <div class="price-value">$59.99 <span>/per year</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Ultra</h3>
                <div class="price-value">$79.99 <span>/per year</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0 offset-md-3"
          >
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="title">Pro</h3>
                <div class="price-value">$99.99 <span>/per year</span></div>
              </div>
              <ul class="pricing-content">
                <li>Managment & Marketing</li>
                <li>SEO Optimization</li>
                <li>25 Analytics Campaign</li>
                <li>1,300 Change Keywords</li>
                <li>25 Social Media Reviews</li>
                <li>1 Free Optimization</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rocket fadeInUp wow slow">
    <img src="assets/img/rocket.png" alt="rocket" />
  </div>
</section> -->

<div class="partner-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="partner-slides owl-carousel owl-theme">
      <div class="partner-item">
        <a href="#"
          ><img
            src="assets/img/partner-1.png"
            class="rounded-img"
            alt="partner"
        /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-2.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-3.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-4.png" alt="partner" /></a>
      </div>
      <!-- <div class="partner-item">
        <a href="#"
          ><img
            src="assets/img/partner-1.png"
            class="rounded-img"
            alt="partner"
        /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-2.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-3.png" alt="partner" /></a>
      </div>
      <div class="partner-item">
        <a href="#"><img src="assets/img/partner-4.png" alt="partner" /></a>
      </div> -->
    </div>
  </div>
  <div class="vector"><img src="assets/img/vector-bg.png" alt="vector" /></div>
</div>

<!-- <section class="blog-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Our News</span>
      <h3>Latest Blog Posts</h3>
      <p>
        We enjoy adapting our strategies to offer every client the best
        solutions that are at the forefront of the industry.
      </p>
      <a routerLink="/blog-1" class="read-more-btn">View More News</a>
    </div>
    <div class="blog-slides owl-carousel owl-theme">
      <div class="single-blog-item bg-1">
        <span>Business & Tech</span>
        <h3>
          <a routerLink="/blog-details"
            >How to Market Your Marketing Agency in 2020</a
          >
        </h3>
        <p>
          Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus
          ac digni ssim vest ibul lorem pretium semper lectus ac digni...
        </p>
        <a routerLink="/blog-details" title="Read More" class="link-btn"
          ><i class="icofont-stylish-right"></i
        ></a>
      </div>

      <div class="single-blog-item bg-2">
        <span>Testing & Tools</span>
        <h3>
          <a routerLink="/blog-details"
            >15 Testing Tools for Optimizing Your Site in 2020</a
          >
        </h3>
        <p>
          Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus
          ac digni ssim vest ibul lorem pretium semper lectus ac digni...
        </p>
        <a routerLink="/blog-details" title="Read More" class="link-btn"
          ><i class="icofont-stylish-right"></i
        ></a>
      </div>

      <div class="single-blog-item bg-3">
        <span>User & Experience</span>
        <h3>
          <a routerLink="/blog-details"
            >12 Tried-and-True Ways to Improve User Experience</a
          >
        </h3>
        <p>
          Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus
          ac digni ssim vest ibul lorem pretium semper lectus ac digni...
        </p>
        <a routerLink="/blog-details" title="Read More" class="link-btn"
          ><i class="icofont-stylish-right"></i
        ></a>
      </div>

      <div class="single-blog-item bg-4">
        <span>Apps & Trend</span>
        <h3>
          <a routerLink="/blog-details"
            >The 13 Best Time Tracking Apps of 2020</a
          >
        </h3>
        <p>
          Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus
          ac digni ssim vest ibul lorem pretium semper lectus ac digni...
        </p>
        <a routerLink="/blog-details" title="Read More" class="link-btn"
          ><i class="icofont-stylish-right"></i
        ></a>
      </div>

      <div class="single-blog-item bg-5">
        <span>Business & Document</span>
        <h3>
          <a routerLink="/blog-details"
            >How to Document Processes to Scale Your Agency Faster</a
          >
        </h3>
        <p>
          Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus
          ac digni ssim vest ibul lorem pretium semper lectus ac digni...
        </p>
        <a routerLink="/blog-details" title="Read More" class="link-btn"
          ><i class="icofont-stylish-right"></i
        ></a>
      </div>
    </div>
  </div>
</section> -->

<app-subscribe></app-subscribe>
