<div class="page-title animatedBackground">
  <div class="container">
    <h3>Services</h3>
    <ul>
      <li><a routerLink="/">Home</a></li>
      <li class="dot-divider"></li>
      <li>Services</li>
    </ul>
  </div>
  <div class="bg-pattern">
    <img src="assets/img/bg-pattern-2.png" alt="pattern" />
  </div>
</div>

<section class="services-area ptb-100 bg-fbf9f8">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-brand-designfloat"></i>
          </div>
          <h3>Digital design & Branding</h3>
          <p>
            Ontwerpen van digitale interfaces en creëeren van herkenbare
            branding welke uw zakelijke waarden weerspiegelen.
          </p>
          <a
            routerLink="/services-details/digital-design-and-branding"
            class="read-more-btn"
            >Read More</a
          >
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-code"></i>
          </div>
          <h3>Web development</h3>
          <p>
            Het ontwikkelen van creatieve digitale oplossingen ondersteund door
            de nieuwste technologieën in de industrie.
          </p>
          <a
            routerLink="/services-details/web-development"
            class="read-more-btn"
            >Read More</a
          >
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-rocket-alt-1"></i>
          </div>
          <h3>Mobile development</h3>
          <p>
            Ios en android geen probleem! Via onze mobile first approach bieden
            wij de beste ervaring aan gebruikers.
          </p>
          <a
            routerLink="/services-details/mobile-development"
            class="read-more-btn"
            >Read More</a
          >
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 pt-4">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-cart"></i>
          </div>
          <h3>E-commerce</h3>
          <p>
            E-commerce is geëvolueerd om ervoor te zorgen dat producten
            gemakkelijker worden gevonden en gekocht. Wij zorgen er voor dat
            websitebezoekers worden omgezet in loyale klanten.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 pt-4">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-chart-arrows-axis"></i>
          </div>
          <h3>Reports & Analytics</h3>
          <p>
            Tegenwoordig is data niet meer weg te denken uit dit digitale
            tijdperk. Wij zorgen er voor dat u grip heeft op deze data door
            uitgebreiden analytics te genereren van uw website.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscribe></app-subscribe>
